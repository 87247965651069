import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { format } from 'date-fns';


interface FiatPayment {
  screenshot_url: string;
  transaction_number: string;
  id: number;
  userid: string;
  currency: string;
  orderid: string;
  utr_no: string;
  amount: number;
  created_at: string;
  updated_at: string;
  status: string;
  image: string;
  username: string;
}

interface FiatStats {
  total_transactions: number;
  total_amount: number;
  successful_transactions: number;
  processing_transactions: number;
  failed_transactions: number;
}
interface PaymentStats {
  total_transactions: number;
  total_amount: number;
  successful_transactions: number;
  processing_transactions: number;
  failed_transactions: number;
}

interface FiatPayment extends Payment {}

interface FiatStats extends PaymentStats {}

// Interfaces
interface PaymentStats {
  total_transactions: number;
  total_amount: number;
  successful_transactions: number;
  processing_transactions: number;
  failed_transactions: number;
}

interface Payment {
  id: number;
  userid: string;
  coinid: number;
  price: number;
  orderid: string;
  chain: string;
  deposit_address: string;
  amount: number;
  memo: string;
  checkout_url: string;
  confirms_needed: number;
  created_at: string;
  updated_at: string;
  status: string;
  username: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
}

// Stats Card Component
const StatsCard = ({ 
  title, 
  value, 
  colorClass = "text-gray-900" 
}: { 
  title: string; 
  value: number | string; 
  colorClass?: string; 
}) => (
  <div className="bg-white p-4 rounded-lg shadow">
    <h3 className="text-gray-500 text-sm font-medium">{title}</h3>
    <p className={`text-2xl font-semibold ${colorClass}`}>{value}</p>
  </div>
);

// Pagination Component
const Pagination = ({ 
  paginationInfo, 
  onPageChange 
}: { 
  paginationInfo: PaginationInfo; 
  onPageChange: (page: number) => void;
}) => {
  const { currentPage, totalPages } = paginationInfo;

  return (
    <div className="flex items-center justify-between px-4 py-3 border-t">
      <div className="flex items-center">
        <p className="text-sm text-gray-700">
          Showing page {currentPage} of {totalPages}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-100"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        {[...Array(Math.min(5, totalPages))].map((_, idx) => {
          let pageNumber;
          if (totalPages <= 5) {
            pageNumber = idx + 1;
          } else if (currentPage <= 3) {
            pageNumber = idx + 1;
          } else if (currentPage >= totalPages - 2) {
            pageNumber = totalPages - (4 - idx);
          } else {
            pageNumber = currentPage - 2 + idx;
          }

          return (
            <button
              key={idx}
              onClick={() => onPageChange(pageNumber)}
              className={`px-3 py-1 rounded-md ${
                currentPage === pageNumber
                  ? 'bg-blue-500 text-white'
                  : 'hover:bg-gray-100'
              }`}
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-100"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

// Main Deposit Component
const Deposit = () => {
  // State declarations
  const [activeTab, setActiveTab] = useState<'crypto' | 'fiat'>('crypto');
  const [payments, setPayments] = useState<Payment[]>([]);
  const [stats, setStats] = useState<PaymentStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fiatPayments, setFiatPayments] = useState<FiatPayment[]>([]);
const [fiatStats, setFiatStats] = useState<FiatStats | null>(null);
const [currencyFilter, setCurrencyFilter] = useState('');
const [loadingAction, setLoadingAction] = useState<number | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10
  });
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleViewImage = async (imageUrl: string) => {
    try {
      // Extract the filename from the provided URL
      const filePath = imageUrl.split("/uploads/")[1];
      const filename = filePath.split("/").pop();

      if (!filename) {
        console.error("Invalid file path or filename.");
        return;
      }

      // Construct the API URL to fetch the image
      const viewUrl = `https://api.xoxowin.com/pkr/pkr-deposits/image/${filename}`;

      // Fetch the image from the backend
      const response = await fetch(viewUrl);

      if (!response.ok) {
        throw new Error("Failed to fetch the image.");
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a local object URL for the image
      const localImageUrl = URL.createObjectURL(blob);

      // Set the local image URL to state
      setSelectedImage(localImageUrl);
    } catch (error) {
      console.error("Error fetching or displaying the image:", error);
    }
  };
  // Fetch payments with pagination
  const fetchPayments = async () => {
    setLoading(true);
    try {
      let url = 'https://api.xoxowin.com/depositHistory/payments';
      const params = new URLSearchParams();

      params.append('page', paginationInfo.currentPage.toString());
      params.append('limit', paginationInfo.itemsPerPage.toString());
      
      if (statusFilter) params.append('status', statusFilter);
      if (startDate) params.append('startDate', startDate);
      if (endDate) params.append('endDate', endDate);
      if (searchTerm) params.append('userid', searchTerm);

      url += `?${params.toString()}`;

      const response = await axios.get(url);
      if (response.data.success) {
        setPayments(response.data.data);
        setPaginationInfo(prev => ({
          ...prev,
          currentPage: response.data.page,
          totalPages: Math.ceil(response.data.total / prev.itemsPerPage),
          totalItems: response.data.total
        }));
      }
    } catch (err) {
      setError('Failed to fetch payments');
      console.error(err);
    }
    setLoading(false);
  };

  // Fetch stats
  const fetchStats = async () => {
    try {
      const response = await axios.get('https://api.xoxowin.com/depositHistory/payments/stats');
      if (response.data.success) {
        setStats(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch stats:', err);
    }
  };
  const fetchFiatPayments = async () => {
    setLoading(true);
    try {
      let url = 'https://api.xoxowin.com/depositHistory/fiat/deposits';
      const params = new URLSearchParams();
  
      params.append('page', paginationInfo.currentPage.toString());
      params.append('limit', paginationInfo.itemsPerPage.toString());
      
      if (statusFilter) params.append('status', statusFilter);
      if (startDate) params.append('startDate', startDate);
      if (endDate) params.append('endDate', endDate);
      if (searchTerm) params.append('userid', searchTerm);
      if (currencyFilter) params.append('currency', currencyFilter);
  
      url += `?${params.toString()}`;
  
      const response = await axios.get(url);
      if (response.data.success) {
        setFiatPayments(response.data.data);
        setPaginationInfo(prev => ({
          ...prev,
          currentPage: response.data.page,
          totalPages: Math.ceil(response.data.total / prev.itemsPerPage),
          totalItems: response.data.total
        }));
      }
    } catch (err) {
      setError('Failed to fetch fiat payments');
      console.error(err);
    }
    setLoading(false);
  };
  
  const fetchFiatStats = async () => {
    try {
      const response = await axios.get('https://api.xoxowin.com/depositHistory/fiat/stats');
      if (response.data.success) {
        setFiatStats(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch fiat stats:', err);
    }
  };
  
  // Effect hooks
  useEffect(() => {
    if (activeTab === 'crypto') {
      fetchPayments();
      fetchStats();
    } else {
      fetchFiatPayments();
      fetchFiatStats();
    }
  }, [activeTab, paginationInfo.currentPage, statusFilter, startDate, endDate, currencyFilter]);

  // Handlers
  const handlePageChange = (newPage: number) => {
    setPaginationInfo(prev => ({ ...prev, currentPage: newPage }));
  };
  const handleApprove = async (id: number) => {
    setLoadingAction(id);
    try {
      const response = await fetch(`https://api.xoxowin.com/pkr/pkr-deposit/approve/${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Failed to approve payment');
      }
      alert('Payment approved successfully');
      setFiatPayments((prev) =>
        prev.map((payment) => (payment.id === id ? { ...payment, status: 'approved' } : payment))
      );
    } catch (err:any) {
      alert(err.message || 'An error occurred');
    } finally {
      setLoadingAction(null);
    }
  };

  const handleReject = async (id: number) => {
    setLoadingAction(id);
    try {
      const response = await fetch(`https://api.xoxowin.com/pkr/pkr-deposit/reject/${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Failed to reject payment');
      }
      alert('Payment rejected successfully');
      setFiatPayments((prev) =>
        prev.map((payment) => (payment.id === id ? { ...payment, status: 'rejected' } : payment))
      );
    } catch (err:any) {
      alert(err.message || 'An error occurred');
    } finally {
      setLoadingAction(null);
    }
  };

  const handleClearFilters = () => {
    setSearchTerm('');
    setStatusFilter('');
    setStartDate('');
    setEndDate('');
    setCurrencyFilter('');
    setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
  };
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'success':
        return 'bg-green-100 text-green-800';
      case 'processing':
        return 'bg-yellow-100 text-yellow-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="p-4 ml-0 lg:ml-[256px]">
      {/* Tabs */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setActiveTab('crypto')}
          className={`px-4 py-2 rounded-lg ${
            activeTab === 'crypto' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Crypto Payments
        </button>
        <button
          onClick={() => setActiveTab('fiat')}
          className={`px-4 py-2 rounded-lg ${
            activeTab === 'fiat' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Fiat Payments
        </button>
      </div>

      {activeTab === 'crypto' && (
        <>
          {/* Stats Cards */}
          {stats && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
              <StatsCard 
                title="Total Transactions" 
                value={stats.total_transactions}
              />
              <StatsCard 
                title="Total Amount" 
                value={`$${stats.total_amount.toFixed(2)}`}
              />
              <StatsCard 
                title="Successful" 
                value={stats.successful_transactions}
                colorClass="text-green-600"
              />
              <StatsCard 
                title="Processing" 
                value={stats.processing_transactions}
                colorClass="text-yellow-600"
              />
              <StatsCard 
                title="Failed" 
                value={stats.failed_transactions}
                colorClass="text-red-600"
              />
            </div>
          )}

          {/* Filters */}
          <div className="bg-white p-4 rounded-lg shadow mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
                <input
                  type="text"
                  placeholder="Search by User ID or Order ID"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
                  }}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                <select
                  value={statusFilter}
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                    setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
                  }}
                  className="w-full p-2 border rounded-lg"
                >
                  <option value="">All</option>
                  <option value="Success">Success</option>
                  <option value="processing">Processing</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
                  }}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
                  }}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                onClick={handleClearFilters}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
              >
                Clear Filters
              </button>
            </div>
          </div>

          {/* Payments Table */}
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">User ID</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">User Name</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Chain</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Created At</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Address</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={7} className="text-center py-4">Loading...</td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan={7} className="text-center py-4 text-red-500">{error}</td>
                  </tr>
                ) : payments.length === 0 ? (
                  <tr>
                    <td colSpan={7} className="text-center py-4">No payments found</td>
                  </tr>
                ) : (
                  payments.map((payment) => (
                    <tr key={payment.id} className="border-t border-gray-200">
                      <td className="px-4 py-2">{payment.userid}</td>
                      <td className="px-4 py-2">{payment.username}</td>
                      <td className="px-4 py-2">{payment.chain}</td>
                      <td className="px-4 py-2">{payment.amount}</td>
                      <td className="px-4 py-2">
                        <span className={`inline-flex px-2 py-1 text-xs rounded-full ${getStatusColor(payment.status)}`}>
                          {payment.status}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        {new Date(payment.created_at).toLocaleString()}
                      </td>
                      <td className="px-4 py-2">
                        <span className="text-xs">{payment.deposit_address}</span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {!loading && !error && payments.length > 0 && (
              <Pagination 
                paginationInfo={paginationInfo}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </>
      )}
{activeTab === 'fiat' && (
  <>
    {/* Stats Cards */}
    {stats && (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
       <StatsCard 
  title="Total Transactions" 
  value={fiatStats?.total_transactions ?? 0}
/>
<StatsCard 
  title="Total Amount" 
  value={`${(fiatStats?.total_amount ?? 0).toFixed(2)}`}
/>
<StatsCard 
  title="Successful" 
  value={fiatStats?.successful_transactions ?? 0}
  colorClass="text-green-600"
/>
<StatsCard 
  title="Processing" 
  value={fiatStats?.processing_transactions ?? 0}
  colorClass="text-yellow-600"
/>
<StatsCard 
  title="Rejected" 
  value={fiatStats?.failed_transactions ?? 0}
  colorClass="text-red-600"
/>

      </div>
    )}

    {/* Filters */}
    <div className="bg-white p-4 rounded-lg shadow mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
          <input
            type="text"
            placeholder="Search by User ID"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
            }}
            className="w-full p-2 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
              setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
            }}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">All</option>
            <option value="approved">Approved</option>
            <option value="processing">Processing</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Currency</label>
          <select
            value={currencyFilter}
            onChange={(e) => {
              setCurrencyFilter(e.target.value);
              setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
            }}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">All</option>
            <option value="INR">INR</option>
            <option value="PKR">PKR</option>
            <option value="AED">AED</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
            }}
            className="w-full p-2 border rounded-lg"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              setPaginationInfo(prev => ({ ...prev, currentPage: 1 }));
            }}
            className="w-full p-2 border rounded-lg"
          />
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={handleClearFilters}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
        >
          Clear Filters
        </button>
      </div>
    </div>

    {/* Fiat Payments Table */}
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">User ID</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">User Name</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Currency</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">UTR No.</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Created At</th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={8} className="text-center py-4">Loading...</td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={8} className="text-center py-4 text-red-500">{error}</td>
            </tr>
          ) : fiatPayments.length === 0 ? (
            <tr>
              <td colSpan={8} className="text-center py-4">No payments found</td>
            </tr>
          ) : (
            fiatPayments.map((payment) => (
              <tr key={payment.id} className="border-t border-gray-200">
                <td className="px-4 py-2">{payment.userid}</td>
                <td className="px-4 py-2">{payment.username}</td>
                <td className="px-4 py-2">{payment.currency}</td>
                <td className="px-4 py-2">{payment.amount}</td>
                <td className="px-4 py-2">{payment.utr_no || payment.transaction_number}</td>
                <td className="px-4 py-2">
                  <span className={`inline-flex px-2 py-1 text-xs rounded-full ${getStatusColor(payment.status)}`}>
                    {payment.status}
                  </span>
                </td>
                <td className="px-4 py-2">
                  {new Date(payment.created_at).toLocaleString()}
                </td>
                <td className="px-4 py-2">
                  <div className="flex space-x-2">
                    {payment.status === 'processing' && (
                      <>
                        <button
                          onClick={() => handleApprove(payment.id)}
                          className="px-3 py-1 text-white bg-green-600 rounded hover:bg-green-700 disabled:bg-gray-400"
                          disabled={loadingAction === payment.id}
                        >
                          {loadingAction === payment.id ? "Approving..." : "Approve"}
                        </button>
                        <button
                          onClick={() => handleReject(payment.id)}
                          className="px-3 py-1 text-white bg-red-600 rounded hover:bg-red-700 disabled:bg-gray-400"
                          disabled={loadingAction === payment.id}
                        >
                          {loadingAction === payment.id ? "Rejecting..." : "Reject"}
                        </button>
                      </>
                    )}
                    {(payment.image || payment.screenshot_url) && (
                      <button
                        onClick={() => handleViewImage(payment.image || payment.screenshot_url)}
                        className="px-3 py-1 text-white bg-blue-600 rounded hover:bg-blue-700"
                      >
                        View
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] overflow-auto">
            <button
              onClick={() => {
                setSelectedImage(null);
                URL.revokeObjectURL(selectedImage); // Revoke the local URL
              }}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
            <img
              src={selectedImage}
              alt="Deposit Screenshot"
              className="max-w-full h-auto"
            />
          </div>
        </div>
      )}
      {!loading && !error && fiatPayments.length > 0 && (
        <Pagination 
          paginationInfo={paginationInfo}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  </>
)}
    </div>
  );
};

export default Deposit;