
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TextField,
  ButtonGroup,
  Typography,
  Box,
  Grid,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import {
  Add as PlusIcon,
  Edit as EditIcon,
  Delete as TrashIcon,
  Close as CloseIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { Buffer } from 'buffer';

const BASE_URL = 'https://api.xoxowin.com/bankdetails';

const BankDetails = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState('INR');
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [imagePreview, setImagePreview] = useState('');
  const [formData, setFormData] = useState({
    bankName: '',
    accountNumber: '',
    ifscCode: '',
    accountHolderName: '',
    upiId: '',
    qrImage: null
  });

  const coins = ['INR', 'MVR', 'AED', 'PKR', 'BDT'];

  useEffect(() => {
    fetchPaymentDetails();
  }, [selectedCoin]);

  const fetchPaymentDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/${selectedCoin}`);
      if (!response.ok) {
        throw new Error('Failed to fetch payment details');
      }
      const data = await response.json();
      setPaymentDetails(data);
    } catch (err:any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      bankName: '',
      accountNumber: '',
      ifscCode: '',
      accountHolderName: '',
      upiId: '',
      qrImage: null
    });
    setImagePreview('');
    setIsEditing(false);
    setEditingId(null);
  };

  const handleEdit = (detail:any) => {
    setEditingId(detail.id);
    setFormData({
      bankName: detail.bank_name || '',
      accountNumber: detail.account_number || '',
      ifscCode: detail.ifsc_code || '',
      accountHolderName: detail.account_holder_name || '',
      upiId: detail.upi_id || '',
      qrImage: null
    });
    // Set image preview if QR exists
    if (detail.qr_image) {
      setImagePreview(`data:image/png;base64,${bufferToBase64(detail.qr_image)}`);
    }
    setIsEditing(true);
  };

  const handleFileChange = (e:any) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({...prev, qrImage: file}));
      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setFormData(prev => ({...prev, qrImage: null}));
    setImagePreview('');
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const formDataToSend = new FormData();
      
      // Add all relevant fields regardless of coin type
      if (formData.bankName) formDataToSend.append('bank_name', formData.bankName);
      if (formData.accountNumber) formDataToSend.append('account_number', formData.accountNumber);
      if (formData.ifscCode) formDataToSend.append('ifsc_code', formData.ifscCode);
      if (formData.accountHolderName) formDataToSend.append('account_holder_name', formData.accountHolderName);
      if (formData.upiId) formDataToSend.append('upi_id', formData.upiId);
      if (formData.qrImage) formDataToSend.append('qr_image', formData.qrImage);

      let response;
      if (editingId) {
        response = await fetch(`${BASE_URL}/${selectedCoin}/${editingId}`, {
          method: 'PUT',
          body: formDataToSend
        });
      } else {
        response = await fetch(`${BASE_URL}/${selectedCoin}`, {
          method: 'POST',
          body: formDataToSend
        });
      }

      if (!response.ok) {
        throw new Error('Failed to save payment details');
      }

      await fetchPaymentDetails();
      resetForm();
    } catch (err:any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const bufferToBase64 = (buffer: { data: any }) => {
    if (!buffer || !buffer.data) return '';
    return Buffer.from(buffer.data).toString('base64');
  };
  const handleDeleteClick = (id: number) => {
    setDeletingId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!deletingId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}/${selectedCoin}/${deletingId}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete payment detail');
      }

      await fetchPaymentDetails();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
      setDeletingId(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeletingId(null);
  };
// Update the table row actions
const renderTableActions = (detail: any) => (
    <ButtonGroup size="small">
      <Button
        onClick={() => handleEdit(detail)}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
      <Button
        onClick={() => handleDeleteClick(detail.id)}
        startIcon={<TrashIcon />}
        color="error"
      >
        Delete
      </Button>
    </ButtonGroup>
  );

  // Add confirmation dialog
  const renderDeleteConfirmDialog = () => (
    <Dialog
      open={deleteDialogOpen}
      onClose={handleDeleteCancel}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this payment detail? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteCancel} disabled={loading}>
          Cancel
        </Button>
        <Button 
          onClick={handleDeleteConfirm} 
          color="error" 
          disabled={loading}
          autoFocus
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Bank Details Fields */}
        {selectedCoin !== 'INR' && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Bank Name"
                value={formData.bankName}
                onChange={(e) => setFormData(prev => ({...prev, bankName: e.target.value}))}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Account Number"
                value={formData.accountNumber}
                onChange={(e) => setFormData(prev => ({...prev, accountNumber: e.target.value}))}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="IFSC Code"
                value={formData.ifscCode}
                onChange={(e) => setFormData(prev => ({...prev, ifscCode: e.target.value}))}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Account Holder Name"
                value={formData.accountHolderName}
                onChange={(e) => setFormData(prev => ({...prev, accountHolderName: e.target.value}))}
                disabled={loading}
              />
            </Grid>
          </>
        )}

        {/* UPI Field for INR */}
        {selectedCoin === 'INR' && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="UPI ID"
              value={formData.upiId}
              onChange={(e) => setFormData(prev => ({...prev, upiId: e.target.value}))}
              disabled={loading}
            />
          </Grid>
        )}

        {/* QR Image Upload - Now available for all coins */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ height: '56px' }}
              disabled={loading}
            >
              {formData.qrImage ? 'Change QR Image' : 'Upload QR Image'}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>

            {/* Image Preview */}
            {imagePreview && (
              <Box sx={{ position: 'relative', width: 'fit-content' }}>
                <img
                  src={imagePreview}
                  alt="QR Preview"
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    bgcolor: 'background.paper',
                    '&:hover': { bgcolor: 'background.default' }
                  }}
                  size="small"
                  onClick={handleRemoveImage}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Form Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <Button
              variant="outlined"
              onClick={resetForm}
              startIcon={<CloseIcon />}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : editingId ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Box className="ml-0 lg:ml-[256px] p-2">
      <Card>
        <CardHeader 
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5">Payment Details Management</Typography>
              <ButtonGroup variant="outlined" size="medium">
                {coins.map(coin => (
                  <Button
                    key={coin}
                    variant={selectedCoin === coin ? "contained" : "outlined"}
                    onClick={() => setSelectedCoin(coin)}
                    disabled={loading}
                  >
                    {coin}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          }
        />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Add/Edit Form */}
            {isEditing && renderForm()}

            {/* Details List */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {selectedCoin === 'INR' ? (
                      <>
                        <TableCell>UPI ID</TableCell>
                        <TableCell>QR Image</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>IFSC Code</TableCell>
                        <TableCell>Account Holder</TableCell>
                        <TableCell>QR Image</TableCell>
                      </>
                    )}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell 
                        colSpan={selectedCoin === 'INR' ? 3 : 6} 
                        align="center"
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && paymentDetails.length === 0 ? (
                    <TableRow>
                      <TableCell 
                        colSpan={selectedCoin === 'INR' ? 3 : 6} 
                        align="center"
                        sx={{ color: 'text.secondary' }}
                      >
                        No payment details found
                      </TableCell>
                    </TableRow>
                  ) : (
                    paymentDetails.map((detail:any) => (
                      <TableRow key={detail.id}>
                        {selectedCoin === 'INR' ? (
                          <>
                            <TableCell>{detail.upi_id}</TableCell>
                            <TableCell>
                              {detail.qr_image && (
                                <img 
                                  src={`data:image/png;base64,${bufferToBase64(detail.qr_image)}`}
                                  alt="QR Code"
                                  style={{ maxWidth: '100px' }}
                                />
                              )}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{detail.bank_name}</TableCell>
                            <TableCell>{detail.account_number}</TableCell>
                            <TableCell>{detail.ifsc_code}</TableCell>
                            <TableCell>{detail.account_holder_name}</TableCell>
                            <TableCell>
                              {detail.qr_image && (
                                <img 
                                  src={`data:image/png;base64,${bufferToBase64(detail.qr_image)}`}
                                  alt="QR Code"
                                  style={{ maxWidth: '100px' }}
                                />
                              )}
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">
                        {renderTableActions(detail)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Add New Button */}
            {!isEditing && (
              <Button
                variant="contained"
                fullWidth
                startIcon={<PlusIcon />}
                onClick={() => setIsEditing(true)}
                disabled={loading}
              >
                Add New Payment Detail
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* Error Snackbar */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      {renderDeleteConfirmDialog()}
    </Box>
  );
};

export default BankDetails;