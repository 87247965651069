import React, { useState, useEffect } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TextField, Button, FormControl,
  Grid, Box, Typography, IconButton, Autocomplete
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

interface User {
  id: string;
  name: string;
}

interface RedeemBonus {
  userid: string;
  code: string;
  amount: number;
  status: string;
  createdat: string;
  updatedat: string;
}

const ROLE_KEY = "3c95e1f2a6b8d740c9e3812f5d7694b0a2c8157e943fd6802b5e9c71m4a3h8p9";
const API_BASE = "https://api.xoxowin.com/bonus";

const BonusRedemption: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [redeemList, setRedeemList] = useState<RedeemBonus[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const headers = {
    "role-key": ROLE_KEY,
    "Content-Type": "application/json"
  };

  const fetchUsers = async (): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE}/users`, { headers });
      const data: User[] = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRedeemList = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE}/redeem-bonus`, { headers });
      const data: RedeemBonus[] = await response.json();
      setRedeemList(data);
    } catch (error) {
      console.error('Error fetching redeem list:', error);
    }
    setLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      await fetch(`${API_BASE}/redeem-bonus/create`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          userid: selectedUser.id,
          amount: Number(amount)
        })
      });
      setAmount('');
      setSelectedUser(null);
      fetchRedeemList();
    } catch (error) {
      console.error('Error creating bonus:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRedeemList();
  }, []);

  return (
    <div className="ml-0 lg:ml-[256px] p-2">
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Create Bonus Redemption
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={users}
                    value={selectedUser}
                    onChange={(_, newValue) => setSelectedUser(newValue)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField 
                        {...params} 
                        label="Search User"
                        placeholder="Type to search..."
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={!selectedUser || !amount}
                >
                  Create Redemption
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Paper>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              Redemption List
            </Typography>
            <IconButton onClick={fetchRedeemList} disabled={loading}>
              <RefreshIcon className={loading ? 'animate-spin' : ''} />
            </IconButton>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redeemList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.userid}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{new Date(item.createdat).toLocaleString()}</TableCell>
                    <TableCell>{new Date(item.updatedat).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default BonusRedemption;