import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Alert,
  Stack,
  Chip,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  SelectChangeEvent
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

interface SportsBet {
  id: number;
  username:string;
  user_id:number;
  match_title: string;
  team_one: string;
  team_two: string;
  game_type: string;
  selection_name: string;
  category: string;
  bet_type: string;
  odds: number;
  stake_amount: number;
  usd_amount: number;
  status: string;
  match_start_time: string;
  match_end_time: string;
}

interface ApiResponse {
  success: boolean;
  bets: SportsBet[];
  pagination: {
    total: number;
    totalPages: number;
    page: number;
    limit: number;
  };
  error?: string;
}

interface Filters {
  status: string;
  gameType: string;
  startDate: string;
  endDate: string;
  currency: string;
}

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const GAME_TYPES = [
  { value: "cricket", label: "Cricket" },
  { value: "football", label: "Football" },
  { value: "basketball", label: "Basketball" },
  { value: "tennis", label: "Tennis" }
];

const STATUS_TYPES = [
  { value: "pending", label: "Pending" },
  { value: "won", label: "Won" },
  { value: "lost", label: "Lost" }
];

const DeleteDialog = ({ open, onClose, onConfirm }: DeleteDialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      Are you sure you want to delete this bet? This action cannot be undone.
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} color="error" variant="contained">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

const SportsBetting = () => {
  const [bets, setBets] = useState<SportsBet[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBetId, setSelectedBetId] = useState<number | null>(null);
  
  const [filters, setFilters] = useState<Filters>({
    status: "",
    gameType: "",
    startDate: "",
    endDate: "",
    currency: "usd"
  });

  const fetchBets = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams({
        page: (page + 1).toString(),
        limit: rowsPerPage.toString(),
        ...(filters.status && { status: filters.status }),
        ...(filters.gameType && { gameType: filters.gameType }),
        ...(filters.startDate && { startDate: filters.startDate }),
        ...(filters.endDate && { endDate: filters.endDate }),
        ...(searchTerm && { search: searchTerm }),
        currency: filters.currency
      });

      const response = await fetch(
        `https://api.xoxowin.com/sportsbetting/admin/bets?${searchParams.toString()}`
      );
      const data: ApiResponse = await response.json();

      if (data.success) {
        setBets(data.bets);
        setTotalCount(data.pagination?.total || 0);
      } else {
        setError(data.error || "Failed to fetch bets");
      }
    } catch (err) {
      setError("Failed to fetch bets");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBets();
  }, [page, rowsPerPage, filters, searchTerm]);

  const handleDelete = async () => {
    if (selectedBetId) {
      try {
        const response = await fetch(
          `https://api.xoxowin.com/sportsbetting/admin/bets/${selectedBetId}`,
          { method: "DELETE" }
        );
        const data = await response.json();
        if (data.success) {
          fetchBets();
          setDeleteDialogOpen(false);
        } else {
          setError(data.error || "Failed to delete bet");
        }
      } catch (err) {
        setError("Failed to delete bet");
      }
    }
  };

  const handleStatusUpdate = async (betId: number, newStatus: string) => {
    try {
      const response = await fetch(
        `https://api.xoxowin.com/sportsbetting/admin/bets/${betId}/status`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            status: newStatus,
            resultDeclaredTime: new Date().toISOString()
          })
        }
      );
      const data = await response.json();
      if (data.success) {
        fetchBets();
      } else {
        setError(data.error || "Failed to update bet status");
      }
    } catch (err) {
      setError("Failed to update bet status");
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<string>, betId: number) => {
    handleStatusUpdate(betId, event.target.value);
  };

  const getStatusColor = (status: string): "success" | "error" | "warning" => {
    switch (status.toLowerCase()) {
      case 'won':
        return 'success';
      case 'lost':
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <Box sx={{ p: 3, ml: { xs: 0, lg: '256px' } }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Sports Betting Administration
        </Typography>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Search bets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status}
                  label="Status"
                  onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                >
                  <MenuItem value="">All Status</MenuItem>
                  {STATUS_TYPES.map(status => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Game Type</InputLabel>
                <Select
                  value={filters.gameType}
                  label="Game Type"
                  onChange={(e) => setFilters({ ...filters, gameType: e.target.value })}
                >
                  <MenuItem value="">All Games</MenuItem>
                  {GAME_TYPES.map(game => (
                    <MenuItem key={game.value} value={game.value}>
                      {game.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="date"
                label="Start Date"
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
              
              <TextField
                type="date"
                label="End Date"
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Stack>
          </Grid>
        </Grid>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>User Name</TableCell>
                <TableCell>Match</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Selection</TableCell>
                <TableCell>Odds</TableCell>
                <TableCell align="right">Stake</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : bets.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No bets found
                  </TableCell>
                </TableRow>
              ) : (
                bets.map((bet) => (
                  <TableRow key={bet.id} hover>
                     <TableCell>
                      <Typography variant="body2">{bet.user_id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{bet.username}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{bet.match_title}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {bet.team_one} vs {bet.team_two}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{bet.game_type}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {bet.category}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{bet.selection_name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {bet.bet_type}
                      </Typography>
                    </TableCell>
                    <TableCell>{bet.odds}</TableCell>
                    <TableCell align="right">
                      ${bet.usd_amount.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={bet.status}
                          onChange={(e) => handleStatusChange(e, bet.id)}
                          renderValue={(value) => (
                            <Chip
                              label={value}
                              size="small"
                              color={getStatusColor(value)}
                            />
                          )}
                        >
                          {STATUS_TYPES.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedBetId(bet.id);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />

        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      </Paper>
    </Box>
  );
};

export default SportsBetting;