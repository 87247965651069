import React, { useState, useEffect } from "react";
import axios from "axios";

interface WithdrawItem {
  uid: string;
  date: string;
  wallet: string;
  amount: number;
  coin: string;
  name: string;
  amount_usdt: number;
  status: string;
}

const Withdraw = () => {
  const [withdrawData, setWithdrawData] = useState<WithdrawItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState<string | null>(null);

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  const fetchWithdrawData = async () => {
    try {
      const response = await axios.get('https://api.xoxowin.com/getWithdrawData');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setWithdrawData(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setWithdrawData([]);
      }
    } catch (error) {
      console.error('Failed to fetch withdrawal data:', error);
      setWithdrawData([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = async (uid: string, newStatus: string) => {
    if (updatingStatus) {
      return; // Prevent multiple simultaneous updates
    }

    try {
      setUpdatingStatus(uid);

      // Update local state first
      setWithdrawData(prevData =>
        prevData.map(item =>
          item.uid === uid ? { ...item, status: newStatus } : item
        )
      );

      // Make API call
      await axios.post('https://api.xoxowin.com/updateWithdrawStatus', {
        uid,
        status: newStatus,
      });

      // Fetch fresh data to ensure consistency
      await fetchWithdrawData();
    } catch (error) {
      console.error('Failed to update status:', error);
      
      // Revert local state on error
      setWithdrawData(prevData =>
        prevData.map(item =>
          item.uid === uid
            ? { ...item, status: withdrawData.find(w => w.uid === uid)?.status || item.status }
            : item
        )
      );
    } finally {
      setUpdatingStatus(null);
    }
  };

  const filteredWithdrawData = searchTerm
    ? withdrawData.filter((withdraw) =>
        String(withdraw.uid).includes(searchTerm) ||
        withdraw.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : withdrawData;

  return (
    <div className='ml-0 lg:ml-[256px]'>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="h-[690px] overflow-scroll">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount (Original)</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Wallet Address</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Coin</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount (USDT)</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredWithdrawData.map((withdraw, index) => (
              <tr key={withdraw.uid}>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.uid}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.name}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.date}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.amount}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.wallet}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.coin}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">{withdraw.amount_usdt.toFixed(2)}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white text-nowrap">{withdraw.status}</td>
                <td className="py-3 px-4 border-b border-gray-200 text-white">
                  <select
                    value={withdraw.status}
                    onChange={(e) => handleStatusChange(withdraw.uid, e.target.value)}
                    disabled={updatingStatus === withdraw.uid}
                    className="p-1 border rounded w-full text-black min-w-[100px]"
                  >
                    <option value="In Queue">In Queue</option>
                    <option value="Processing">Processing</option>
                    <option value="Done">Done</option>
                    <option value="Wager Not Completed">Wager Not Completed</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;